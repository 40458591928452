import async from "../components/Async";

import {
  faChartPie,
  faHome,
  faUser,
  faThumbsUp,
  faSchool,
  faChild
} from "@fortawesome/free-solid-svg-icons";

import { withAuthenticationRequired } from "@auth0/auth0-react";

// Dashboards
const Dashboard = async(() => import("../pages/dashboard/Dashboard"));

//Users
const Users = async(() => import("../pages/users/Users"));

// Authorizations
const Pending = async(() => import("../pages/authorizations/Pending"));
const Historic = async(() => import("../pages/authorizations/Historic"));

//Students
const Students = async(() => import("../pages/students/Students"));

// Statistics
const AuthorizationStatistics = async(() => import("../pages/statistics/AuthorizationStatistics"));
const PickupsStatistics = async(() => import("../pages/statistics/PickupStatistics"));

//Pickups
const PickupsInRealtime = async(() => import("../pages/pickups/Realtime"));
const PickupsHistoric = async(() => import("../pages/pickups/Historic"));

//Settings
const Settings = async(() => import("../pages/settings/Settings"));

//Account
const Account = async(() => import("../pages/account/Account"));

// Routes
export const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  icon: faHome,
  component: withAuthenticationRequired(Dashboard),
  children: null
};

export const usersRoutes = {
  path: "/users",
  name: "Usuarios",
  icon: faUser,
  component: withAuthenticationRequired(Users),
  children: null
};

export const autorizationsRoutes = {
  path: "/authorizations",
  name: "Autorizaciones",
  icon: faThumbsUp,
  children: [
    {
      path: "/authorizations_pending",
      name: "Pendientes de aprobación",
      component: withAuthenticationRequired(Pending)
    },    
    {
      path: "/authorizations_historic",
      name: "Histórico",
      component: withAuthenticationRequired(Historic)
    }   
  ]
};

export const studentsRoutes = {
  path: "/students",
  name: "Estudiantes",
  icon: faChild,
  component: withAuthenticationRequired(Students),
  children: null
};

export const pickupsRoutes = {
  path: "/pickups",
  name: "Retiros",
  icon: faSchool,
  children: [
    {
      path: "/pickups_realtime",
      name: "Tiempo real",
      component: withAuthenticationRequired(PickupsInRealtime)
    },    
    {
      path: "/pickups_historic",
      name: "Histórico",
      component: withAuthenticationRequired(PickupsHistoric)
    }   
  ]
};

export const statisticsRoutes = {
  path: "/statistics",
  name: "Estadísticas",
  icon: faChartPie,
  children: [
    {
      path: "/statistics_authorizations",
      name: "Autorizaciones diarias",
      component: withAuthenticationRequired(AuthorizationStatistics)
    },    
    {
      path: "/statistics_pickups",
      name: "Retiros",
      component: withAuthenticationRequired(PickupsStatistics)
    }   
  ]
};

export const settingsRoutes = {
  path: "/settings",
  component: withAuthenticationRequired(Settings)
};

export const accountRoutes = {
  path: "/account",
  component: withAuthenticationRequired(Account)
};