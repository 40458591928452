class Util
{  
    static handleErrors(response) 
    {
        if (!response.ok) 
        {
            let error = Error(response.statusText);
            error.statusCode = response.status;
            throw error;
        }

        return response;
    }

    static getCurrentMonthName()
    {
        return Util.getMonthNames()[new Date().getMonth()];    
    }

    static getMonthNumber(monthName)
    {
        return Util.getMonthNames().indexOf(monthName)+1;
    }

    static getMonthNames()
    {
        return ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    }

    static getCurrentDayName()
    {
        return Util.getDayNames()[new Date().getDay()-1];    
    }

    static getDayNumber(dayName)
    {
        return Util.getDayNames().indexOf(dayName)+1;
    }

    static getDayNames()
    {
        return ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'];
    }
}

export default Util;