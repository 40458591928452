/* eslint-disable no-unused-vars */
import React from "react";
import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
import * as constants from "../constants.js";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/general/Page404";
import ScrollToTop from "../components/ScrollToTop";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { dashboardRoutes, studentsRoutes, autorizationsRoutes, pickupsRoutes, statisticsRoutes, accountRoutes, usersRoutes, settingsRoutes } from "./RouteDefinitions.js";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );

const Routes = () => {
  const { user } = useAuth0();
  let routes = [dashboardRoutes, studentsRoutes, autorizationsRoutes, pickupsRoutes, statisticsRoutes, accountRoutes]

  if (user[constants.ROLE_CLAIM].toString() === constants.ADMIN_ROLE) {
    routes.push(usersRoutes);
    routes.push(settingsRoutes);
  }

  return (
    <HashRouter>
      <ScrollToTop>
        <Switch>
          {childRoutes(DashboardLayout, routes)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
}

export default withAuthenticationRequired(Routes);