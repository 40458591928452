import React from "react";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";

const Page404 = () => (
  <div className="text-center">
    <h1 className="display-1 font-weight-bold">404</h1>
    <p className="h1">Página no encontrada.</p>
    <br></br>
    <Link to="/">
      <Button color="primary" size="lg">
        Volver al dashboard
      </Button>
    </Link>
  </div>
);

export default Page404;
