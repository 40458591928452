import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  NavItem,
  NavLink
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCogs,
  faCog,
  faUserCog,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import Util from "../util.js";
import * as constants from "../constants.js";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const NavbarDropdowns = (sidebar) => {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const [gettingSchoolData, setGettingSchoolData] = useState(false)
  const [getSchoolDataError, setSchoolDataError] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [logoUrl, setlogoUrl] = useState("")
  const [name, setName] = useState("")

  const getPendingAuthorizations = async () => {
    let school = user[constants.SCHOOL_CLAIM];
    let url = `${process.env.REACT_APP_EASYPIK_URL}/school/${school}/authorizations/pending?count=true`;
    let token = await getAccessTokenSilently();

    fetch(url,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(Util.handleErrors)
      .then((res) => res.json())
      .then((data) => {
        setNotifications(
          data > 0 ?
            [
              {
                type: "important",
                title: "Hay autorizaciones para aprobar"
              }
            ] : []);
      })
      .catch((error) => {
        console.log(`Error getting data: ${error}`);
      });
  }

  const getSchool = async () => {
    let school = user[constants.SCHOOL_CLAIM];
    setGettingSchoolData(true);
    setSchoolDataError(false);

    let url = `${process.env.REACT_APP_EASYPIK_URL}/school/${school}`;
    let token = await getAccessTokenSilently();

    fetch(url,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(Util.handleErrors)
      .then((res) => res.json())
      .then((data) => {
        setGettingSchoolData(false);
        setlogoUrl(data.logoUrl);
        setName(data.name);
      })
      .catch((error) => {
        console.log(`Error getting data: ${error}`);
        setGettingSchoolData(false);
        setSchoolDataError(true);
      });
  }

  const history = useHistory();

  useEffect(() => {
    getPendingAuthorizations();
    getSchool();
    const timer = setInterval(() => getPendingAuthorizations(), 30000);
    return () => {
      clearInterval(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Collapse navbar>
      <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>
        {!gettingSchoolData && !getSchoolDataError &&
          <NavItem>
            <NavLink>
              <img height="30"
                src={logoUrl}
                alt={name}
                title={name}
              />
            </NavLink>
          </NavItem>
        }
        <NavItem>
          <NavLink style={{ color: 'gray', textDecoration: 'none' }} disabled>{user.email}</NavLink>
        </NavItem>
        <NavbarDropdown
          header={notifications.length === 1 ? "Notificación Nueva" : "Notificaciones Nuevas"}
          icon={faBell}
          count={notifications.length}
          indicator={notifications.length > 0}
        >
          {notifications.map((item, key) => {
            let icon = <FontAwesomeIcon icon={faBell} className="text-danger" />;

            return (
              <NavLink
                key={key}
                icon={icon}
                onClick={() => history.push("/authorizations_pending") }
              ><FontAwesomeIcon icon={faBell} className="text-danger" />  {item.title}</NavLink>
            );
          })}
        </NavbarDropdown>

        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu right>
            {user[constants.ROLE_CLAIM].toString() === constants.ADMIN_ROLE &&
              <DropdownItem onClick={() => history.push("/settings") }>
                <FontAwesomeIcon
                  icon={faCogs}
                  fixedWidth
                  className="mr-2 align-middle"
                />Configuración
            </DropdownItem>
            }
            <DropdownItem onClick={() => history.push("/account")}>
              <FontAwesomeIcon
                icon={faUserCog}
                fixedWidth
                className="mr-2 align-middle"
              />Cuenta
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => logout({ returnTo: window.location.origin })}>
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                fixedWidth
                className="mr-2 align-middle"
              />Cerrar Sesión
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
}

const NavbarDropdown = ({
  children,
  count,
  header,
  icon,
  active,
  indicator
}) => (
    <UncontrolledDropdown nav inNavbar className="ml-lg-1" active={active}>
      <DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
        <FontAwesomeIcon icon={icon} className="align-middle" />
        {indicator ? <span className="indicator" /> : ""}
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg py-0">
        <div className="dropdown-menu-header position-relative">
          {count} {header}
        </div>
        <ListGroup>{children}</ListGroup>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

const NavbarToggle = connect(store => ({
  app: store.app
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarComponent = ({ sidebar }) => {
  return (
    <Navbar expand className="navbar-theme">
      {!sidebar.isOnRight ? (
        <React.Fragment>
          <NavbarToggle />
          <NavbarDropdowns sidebar={sidebar} />
        </React.Fragment>
      ) : (
          <React.Fragment>
            <NavbarDropdowns />
            <NavbarToggle />
          </React.Fragment>
        )}
    </Navbar>
  );
};

export default connect(store => ({
  sidebar: store.sidebar
}))(withAuthenticationRequired(NavbarComponent, { onRedirecting: () => <div>Loading...</div> }));
